<template>
    <div>
      <div class="row">

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Job Reports</h5>

              <div class="alert border-0 border-info border-start border-5 alert-dismissible fade show">
                <div class="text-white">Using the form below you can filter jobs to generate a report that suits your needs.</div>
              </div>

              <div class="row g-3">
                <div class="col-md-6">
                  <label for="jobReportsClient" class="form-label">Client</label>
                  <Multiselect class="form-control form-select" @change="$nextTick(() => {loadSites()})" style="background-color: rgb(0 0 0 / 15%) !important;" v-model="report.client_id" id="jobReportsClient" valueProp="id" label="name" :searchable="true" :options="clients"></Multiselect>
                </div>
                <div class="col-md-6">
                  <label for="jobReportsSite" class="form-label">Site</label>
                  <Multiselect class="form-control form-select" style="background-color: rgb(0 0 0 / 15%) !important;" v-model="report.site_id" id="jobReportsSite" valueProp="id" label="name" :searchable="true" :options="sites"></Multiselect>
                  <div class="valid-feedback">Site list has been updated!</div>
                </div>
                <div class="col-md-6">
                  <label for="jobReportsStatus" class="form-label">Job Status</label>
                  <select id="jobReportsStatus" v-model="report.status" style=" width: 100%;" class="form-select">
                    <option selected="" value="-1">- Filter by Status -</option>
                    <option value="0">TBA</option>
                    <option value="1">Completed</option>
                    <option value="2">Assigned</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="jobReportsType" class="form-label">Job Type</label>
                  <select id="jobReportsType" v-model="report.type" style=" width: 100%;" class="form-select">
                    <option selected="" value="">- Filter by Type -</option>
                    <option value="Installation">Installation</option>
                    <option value="Service">Service</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Repair">Repair</option>
                    <option value="Investigation">Investigation</option>
                    <option value="Remote">Remote</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="jobReportSortBy" class="form-label">Sort Jobs By</label>
                  <select id="jobReportSortBy" v-model="report.sort_by" style=" width: 100%;" class="form-select">
                    <option selected="" value="">- Sort By -</option>
                    <option value="id">ID</option>
                    <option value="created_at">Date Created</option>
                    <option value="postcode">Postcode</option>
                    <option value="site">Site</option>
                    <option value="client">Client</option>
                    <option value="completed_at">Completed At</option>
                    <option value="completed_by">Completed By</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="jobReportsCreatedFrom" class="form-label">Created From</label>
                  <div id="jobReportsCreatedFrom" class="input-group">
                    <span class="input-group-text">Created From</span>
                    <input type="date" id="jobs-created-from-date" format="dd/MM/yyyy" aria-label="From Date" class="form-control">
                    <span class="input-group-text">To</span>
                    <input type="date" id="jobs-created-to-date" format="dd/MM/yyyy" aria-label="To Date" class="form-control">
                  </div>
                </div>
                <div v-show="report.status == 1" class="col-md-6">
                  <label for="jobReportsCompletedFrom" class="form-label">Completed From</label>
                  <div id="jobReportsCompletedFrom" class="input-group">
                    <span class="input-group-text">Completed From</span>
                    <input type="date" id="jobs-from-date" format="dd/MM/yyyy" aria-label="From Date" class="form-control">
                    <span class="input-group-text">To</span>
                    <input type="date" id="jobs-to-date" format="dd/MM/yyyy" aria-label="To Date" class="form-control">
                  </div>
                </div>
                <div v-show="report.status == 1" class="col-md-6">
                  <label for="jobReportsCompletedBy" class="form-label">Completed By</label>
                  <Multiselect id="jobReportsCompletedBy" class="form-control form-select" style="background-color: rgb(0 0 0 / 15%) !important;" v-model="report.completed_by" valueProp="id" label="name" :searchable="true" :options="users"></Multiselect>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12">
                  <SubmitButton :clicked="generating" class="btn btn-primary float-end" @click="generateWorksheets" text="Generate Worksheets"/>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div v-if="generating" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <p class="text-white font-24 mb-0 fw-bold">{{reportMessage}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import Multiselect from "@vueform/multiselect";
import Swal from "sweetalert2";
import SubmitButton from "../../../../components/SubmitButton";
import PDFMerger from "pdf-merger-js/browser";
import { saveAs } from 'file-saver';

export default {
  name: "Reports",
  components: {
    SubmitButton,
      Multiselect
  },
  data(){
    return {
      clients: [],
      sites: [],
      users: [],
      report: {
        site_id: null
      },
      generating: false,
      reportMessage: ""
    }
  },
  mounted(){
    this.loadClients();
    this.loadSites();
    this.loadUsers();
  },
  methods: {
    loadClients(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/list`)
      .then(response => {
        this.clients = response.data.clients;
      })
      .catch(error => {
        this.$error("Failed to load clients", error);
      })
    },
    loadSites(){
      let url = `${process.env.VUE_APP_API_URL}/v1/sites/list`;
      if(this.report.client_id !== undefined){
        url += '?client_id=' + this.report.client_id;
      }
      axios.get(url)
          .then(response => {
            this.sites = response.data.sites;
            document.getElementById('jobReportsSite').classList.add('is-valid');
            setTimeout(() => {
              document.getElementById('jobReportsSite').classList.remove('is-valid');
            }, 3000);
          })
          .catch(error => {
            this.$error("Failed to load sites", error);
          })
    },
    loadUsers(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/list`)
          .then(response => {
            this.users = [];
            response.data.users.forEach((u) => {
              this.users.push({id: u.id, name: `${u.fname} ${u.lname}`});
            });
          })
          .catch(error => {
            this.$error("Failed to load users", error);
          })
    },
    generateWorksheets(){

      this.reportMessage = "Finding jobs...";
      this.generating = true;

      // 1. Find jobs that match search criteria
      // 2. For each job, generate a worksheet
      // 3. Merge those worksheets into 1 PDF
      // 4. Save the PDF

      let page = 1;

      let fromDate = document.getElementById('jobs-from-date');
      fromDate = fromDate.value;
      let toDate = document.getElementById('jobs-to-date');
      toDate = toDate.value;
      if(parseInt(this.report.status) !== 1){
        fromDate = "";
        toDate = "";
      }

      let createdFromDate = document.getElementById('jobs-created-from-date');
      createdFromDate = createdFromDate.value;
      let createdToDate = document.getElementById('jobs-created-to-date');
      createdToDate = createdToDate.value;
      if(parseInt(this.report.status) !== 1){
        createdFromDate = "";
        createdToDate = "";
      }

      if(this.report.client_id === undefined) this.report.client_id = null;

      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs?page=${page}&type=${this.report.type || ""}
      &status=${this.report.status || ""}&toDate=${toDate}&fromDate=${fromDate}&createdToDate=${createdToDate}&createdFromDate=${createdFromDate}
      &sort_by=${this.report.sort_by || ""}&completed_by=${this.report.completed_by || ""}&client_id=${this.report.client_id}&site_id=${this.report.site_id}&count=10000`)
      .then(response => {

        this.reportMessage = `Found ${response.data.pagination.total} jobs matching your search criteria. Please be aware this may take several minutes, please DO NOT close this tab or browser window during this process.`;
        this.processWorksheetJobs(response.data);

      })
      .catch(error => {
        this.$error("Failed to load jobs", error);
      })

    },
    async processWorksheetJobs(data){

      // Use the pagination data to iterate over all the jobs which match the search criteria and download
      // the worksheet for each of them.

      if(data.jobs.length > 1000){
        Swal.fire({
          icon: 'error',
          title: 'It\'s not you, it\'s me...',
          text: 'It looks like you\'re trying to generate over 1,000 worksheets there. As much as I want to be there for you... this is too much.'
        });
        this.reportMessage = "";
        this.generating = false;
        return;
      }

      if(data.jobs.length < 1){
        this.reportMessage = "";
        this.generating = false;
        return;
      }

      let failed = [];

      let merger = new PDFMerger();
      let count = 1;

      for await (let job of data.jobs){

        this.reportMessage = "Donwloading worksheet #" + count + " of " + data.jobs.length;

        try{
          let response = await axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${job.id}/jobsheet`, {
            responseType: "blob"
          });

          merger.add(response.data);

        }catch(err) {
          failed.push(job);
        }

        count++;

      }

      this.reportMessage = "Merging worksheets...";

      const mergedPdf = await merger.saveAsBlob();
      saveAs(mergedPdf, `Worksheets.pdf`);

      this.reportMessage = "";
      this.generating = false;

    }
  }
}
</script>

<style scoped>

</style>

<style src="@vueform/multiselect/themes/default.css"></style>